.title{
color: #085906;
font-weight: bolder;
}

.linkitem {
  color: rgb(0, 56, 9);
}

.navbar {
  background-color: #9096b8;
 
  

}

.menubars {
  font-size: 1rem;
  background: gray;

}

.navmenu {
  background-color: #060b26;
  width: 200px;
  height: inherit;
  display: flex;
  justify-content: center;
  /* position: fixed; */
  top: 0;
  left: -100%;
  transition: 850ms;
}

.navmenuactive {
  left: 0;
  transition: 350ms;
  background-color: #060b26;
}

.navtext {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.navtext a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 15px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.navtext a:hover {
  background-color: #1a83ff;
}

.navmenuitems {
  width: 100%;
}

.navbartoggle {
  background-color: #060b26;
  width: 100%;
  height: 80px;
  
  align-items: flex-start;
}

span {
  margin-left: 16px;
}