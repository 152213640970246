.hover-up {
	transform: translate(0%, 0%);
}
.hover-up:hover {
	transform: translate(0%, -3%);
}
.svg-border-rounded svg {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 3rem;
	width: 100%;
}
.page-header {
	font-size: 4rem;
	color: #fff;
	padding-top: 20vh;
	padding-bottom: 20vh;
}

.icon-stack {
	display: -webkit-inline-box;
	display: inline-flex;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	border-radius: 100%;
	height: 2rem;
	width: 2rem;
	font-size: 1rem;
	background-color: #eff3f9;
}

.icon-stack-xl {
	height: 5rem;
	width: 5rem;
	font-size: 1.75rem;
}
.z-1 {
	z-index: 1 !important;
	position: relative !important;
}

.bg-primary-soft {
	background-color: #bfd6f8 !important;
}
.bg-warning-soft {
	background-color: #ffd5cb !important;
}
.bg-teal-soft {
	background-color: #bfe8e5 !important;
}
.text-teal {
	color: #00ba94 !important;
}
/* Height */

.h-80vh {
	height: 80vh;
}
/* Padding */
.pt-4rem {
	padding-top: 4rem;
}
.py-4rem {
	padding-top: 4rem;
	padding-bottom: 4rem;
}

.pb-5rem {
	padding-bottom: 5rem;
}

.pb-10vh {
	padding-bottom: 10vh;
}

.py-10vh {
	padding-top: 10vh;
	padding-bottom: 10vh;
}

.p-20vh {
	padding: 20vh;
}
.pt-20vh {
	padding-top: 20vh;
}
.pb-20vh {
	padding-bottom: 20vh;
}
.pl-20vh {
	padding-left: 20vh;
}
.pr-20vh {
	padding-right: 20vh;
}
.py-20vh {
	padding-top: 20vh;
	padding-bottom: 20vh;
}

.px-20vh {
	padding-left: 20vh;
	padding-right: 20vh;
}

/* Margin */

.my-4rem {
	margin-top: 4rem;
	margin-bottom: 4rem;
}

.mt-n5 {
	margin-top: -3rem !important;
}

/* Border */
.border-top {
	border-top: 1px im !important;
	border-style: solid !important;
	border-color: #e3e6ec;
}
.border-top-lg {
	border-top-width: 0.25rem !important;
}
.border-teal {
	border-color: #00ba94 !important;
}
