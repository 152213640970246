.Carousel {
  width: 100vw;
}

.ImageContainer {
  width: 100%;
  height: 80vh;
  background-size: cover;
  background-position: center center;
}

@media screen and (max-width: 767px) {   
  .ImageContainer{
    background-size: scale-down;
    width: 100%;
    height: 80vh;
    background-position: center center;
  } 
}

@media screen and (min-width: 768px) {      
  .ImageContainer{      
    background-size: scale-down;
    width: 100%;
    height: 80vh;
    background-position: center center;
  } 
} 
@media (min-width: 992px) {      
  .ImageContainer{        
    background-size: scale-down;
    width: 100%;
    height: 80vh;
    background-position: center center;
    } 
}
@media (min-width: 1200px) {    
  .ImageContainer{         
    background-size: cover;
    width: 100%;
    height: 80vh;
   } 
}

.Caption h3 {
  text-shadow: 1px 1px 1px rgb(41, 41, 41);
  font-size: 50px;
  color: rgb(230, 225, 218);
  
}

.Caption p {
  text-shadow: 1px 1px 2px rgb(41, 41, 41);
}
