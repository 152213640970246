.thumbnail {
height: 20px;
 width: auto;

}

.hr1 {
  border: 2px solid rgb(0, 0, 0);
}

.img1 {
  opacity: 1;
  display: block;
  width: auto;
  height: 100px;
  transition: .5s ease;
  backface-visibility: hidden;
}

.crd {
  border-radius: 25px;
  background-color: rgb(241, 247, 245);
}

.cbody {
  background-color: rgb(196, 196, 196);
  text-align: center;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
  border-radius: 25px;
}


.container3, .container1, .container2 {
  position: relative;
  width: 50%;
}

.image, .image1, .image2 {
  opacity: 1;
  display: block;
  width: auto;
  height: 80px;
  transition: .5s ease;
  backface-visibility: hidden;
}

.middle, .middle1, .middle2 {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.container:hover > .image,  .container1:hover >.image1, .container2:hover > .image2{
  opacity: 0.1;
}

.container:hover > .middle, .container1:hover > .middle1, .container2:hover > .middle2 {
  opacity: 1;
}

.text {
  background-color: #048500;
  color: white;
  font-size: 16px;
  padding: 16px 32px;
}