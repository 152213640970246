.title {
    color: #696600;
    background-color: #696600;
}
.Logo {
	position: absolute;
	top: -30px;
	left: calc(50% - 30px);
	transition: 0.2s;
	border-radius: 50%;
	border: 1px solid #123ab3;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	width: 72px;
	height: 72px;
	background-color: #fff;
	transition: all 0.15s ease;
	width: 60px;
	height: 60px;
	padding: 5px;
	overflow: hidden;
}

.gif {
    width: 300%;
    height: auto;
    object-fit: fill;
}

.TanImg {
    background-size: scale-down;
    width:300px;
    background-position: center center;
    align-content: center;
}

.scarpImg {
    background-size: scale-down;
    width:auto;
    background-position: center center;
    align-content: center;
}

.Container1 {
   margin-bottom: 50px;
   align-items: center;
   text-align: center;
}

.Container2 {
    align-items: center;
}

.PhilHeader {
    align-content: center;
    text-align: center center;
    color: rgb(144, 146, 141);
}

.Team {
	padding: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-right: 1px solid #f1eeed;
	border-bottom: 1px solid #f1eeed;
	overflow: hidden;
	background: #fff;
	height: 100px;
    margin-bottom: 30px;
}

.Team img {
	height: 50px;
    justify-content: center;
	max-width: 100%;
	vertical-align: middle;
	border-style: none;
	transition: all 0.3s ease-in-out;
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
	transform: scale(0.9);
}

.Team:hover img {
	-webkit-filter: none;
	filter: none;
	transform: scale(1);
}



  @media (min-width: 200px) { 
    .ProfilePic {
        margin-top: 20px;
        position: absolute;
        top: 10px;
        left: calc(45% - 30px);
        transition: 0.2s;
        border-radius: 50%;
        border: 3px solid rgb(72, 16, 150);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        background-color: rgb(31, 31, 31);
        transition: all 0.15s ease;
        width: 100px;
        height: 100px;
        overflow: hidden;
    }

    .ProfilePicUR2 {
        margin-top: 20px;
        position: absolute;
        top: 10px;
        left: calc(45% - 30px);
        transition: 0.2s;
        border-radius: 50%;
        border: 3px solid rgb(10, 250, 190);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        width: 72px;
        height: 72px;
        background-color: rgb(31, 31, 31);
        transition: all 0.15s ease;
        width: 100px;
        height: 100px;
        overflow: hidden;
    }

    .ProfilePicUR1 {
        margin-top: 20px;
        position: absolute;
        top: 10px;
        left: calc(45% - 30px);
        transition: 0.2s;
        border-radius: 50%;
        border: 3px solid rgb(255, 79, 141);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        width: 72px;
        height: 72px;
        background-color: rgb(31, 31, 31);
        transition: all 0.15s ease;
        width: 100px;
        height: 100px;
        overflow: hidden;
    }

    .ProfilePicPDO {
        margin-top: 20px;
        position: absolute;
        top: 10px;
        left: calc(45% - 30px);
        transition: 0.2s;
        border-radius: 50%;
        border: 3px solid rgb(81, 79, 253);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        width: 72px;
        height: 72px;
        background-color: rgb(31, 31, 31);
        transition: all 0.15s ease;
        width: 100px;
        height: 100px;
        overflow: hidden;
    }

    .imgPic {
        max-width: 150px;
        height: 150px;
        border-radius: 50%;
    }

    .ProfileDesc {
        text-align: center;
        border: black;
        color: #000000;
        margin-top: 120px;     
        text-align: center justify;
        font-size: 12px;
    }
  }

  @media (min-width: 530px) {    
    .ProfilePic {
        margin-top: 20px;
        position: absolute;
        top: 10px;
        left: calc(38% - 30px);
        transition: 0.2s;
        border-radius: 50%;
        border: 3px solid rgb(72, 16, 150);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        background-color: rgb(31, 31, 31);
        transition: all 0.15s ease;
        width: 100px;
        height: 100px;
        overflow: hidden;
    }

    .ProfilePicUR2 {
        margin-top: 20px;
        position: absolute;
        top: 10px;
        left: calc(38% - 30px);
        transition: 0.2s;
        border-radius: 50%;
        border: 3px solid rgb(10, 250, 190);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        width: 72px;
        height: 72px;
        background-color: rgb(31, 31, 31);
        transition: all 0.15s ease;
        width: 100px;
        height: 100px;
        overflow: hidden;
    }

    .ProfilePicUR1 {
        margin-top: 20px;
        position: absolute;
        top: 10px;
        left: calc(38% - 30px);
        transition: 0.2s;
        border-radius: 50%;
        border: 3px solid rgb(255, 79, 141);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        width: 72px;
        height: 72px;
        background-color: rgb(31, 31, 31);
        transition: all 0.15s ease;
        width: 100px;
        height: 100px;
        overflow: hidden;
    }

    .ProfilePicPDO {
        margin-top: 20px;
        position: absolute;
        top: 10px;
        left: calc(45% - 30px);
        transition: 0.2s;
        border-radius: 50%;
        border: 3px solid rgb(81, 79, 253);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        width: 72px;
        height: 72px;
        background-color: rgb(31, 31, 31);
        transition: all 0.15s ease;
        width: 100px;
        height: 100px;
        overflow: hidden;
    }

    .imgPic {
        max-width: 150px;
        height: 150px;
        border-radius: 50%;
    }

    .ProfileDesc {
        text-align: center;
        border: black;
        color: #000000;
        margin-top: 120px;     
        text-align: center justify;
        font-size: 12px;
    }
  }

  @media (min-width: 980px) {    
    .ProfilePic {
        margin-top: 20px;
        position: absolute;
        top: 10px;
        left: calc(45% - 30px);
        transition: 0.2s;
        border-radius: 50%;
        border: 3px solid rgb(72, 16, 150);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        background-color: rgb(31, 31, 31);
        transition: all 0.15s ease;
        width: 100px;
        height: 100px;
        overflow: hidden;
    }

    .ProfilePicUR2 {
        margin-top: 20px;
        position: absolute;
        top: 10px;
        left: calc(45% - 30px);
        transition: 0.2s;
        border-radius: 50%;
        border: 3px solid rgb(10, 250, 190);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        width: 72px;
        height: 72px;
        background-color: rgb(31, 31, 31);
        transition: all 0.15s ease;
        width: 100px;
        height: 100px;
        overflow: hidden;
    }

    .ProfilePicUR1 {
        margin-top: 20px;
        position: absolute;
        top: 10px;
        left: calc(45% - 30px);
        transition: 0.2s;
        border-radius: 50%;
        border: 3px solid rgb(255, 79, 141);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        width: 72px;
        height: 72px;
        background-color: rgb(31, 31, 31);
        transition: all 0.15s ease;
        width: 100px;
        height: 100px;
        overflow: hidden;
    }

    .ProfilePicPDO{
        margin-top: 20px;
        position: absolute;
        top: 10px;
        left: 20%;
        transition: 0.2s;
        border-radius: 50%;
        border: 3px solid rgb(81, 79, 253);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        width: 72px;
        height: 72px;
        background-color: rgb(31, 31, 31);
        transition: all 0.15s ease;
        width: 100px;
        height: 100px;
        overflow: hidden;
    }

    .imgPic {
        max-width: 150px;
        height: 150px;
        border-radius: 50%;
    }

    .ProfileDesc {
        text-align: center;
        border: black;
        color: #000000;
        margin-top: 120px;     
        text-align: center justify;
        font-size: 12px;
    }
  }
  @media (min-width: 900px) { 
    .ProfilePicPDO {
        margin-top: 20px;
        position: absolute;
        top: 10px;
        left: calc(48% - 30px);
        transition: 0.2s;
        border-radius: 50%;
        border: 3px solid rgb(81, 79, 253);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        width: 72px;
        height: 72px;
        background-color: rgb(31, 31, 31);
        transition: all 0.15s ease;
        width: 100px;
        height: 100px;
        overflow: hidden;
    }   
  }




.hr1 {
    border: 2px solid rgb(0, 0, 0);
}
.subtitle1 {
    margin-top: 50px;
}

.subtitle2 {
    text-indent: 20px;
    margin-top: 20px;
}


