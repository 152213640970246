.card-form1 {
    width: 120px;
    height: 100px;
    border-radius: 25px;
    border: 2px solid #619e0b;
    padding: 10px;
    font-size: 15px;
    color: rgb(0, 0, 0);
    background-color: rgb(243, 243, 243);
    text-align: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}