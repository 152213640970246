@media (min-width: 200px) { 
    .container {
        padding: 0;
        padding-left: 0;
    }
}

@media (min-width: 200px) { 
    .container1 {
        padding: 0;
        width: 10px;
    }
}

.navbar {
    background-color: rgb(128, 207, 37);
    height: 80px;
    display: flex;
    align-items: center;
}

.menubars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
}

.navmenu {
    background-color: rgb(214, 141, 141);
    margin-top: 30px;
    width: 40px;
    display: flex;
    justify-content: center;
    /* position: fixed; */
    top: 0;
    left: 0%;
    transition: 850ms;
    z-index: 1;
}

