* {
    margin:0;
    padding: 0;

}

body {
    font-family:Arial, Helvetica, sans-serif;
}

.weather {
   
    background-size: cover;
    
    transition: 0.4 ease-out;
}

.weather.sunny-cloudy {
    
}
main {
    display: block !important;
}