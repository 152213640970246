.bottomRow {
  margin: 0px !important;
  text-align: right;
  color: #fff;
  background-color: #415a43;
  font-size: 11px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.partner {
    max-height: 50px;
    max-width: 50px;
    margin: 4px;
}