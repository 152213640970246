.sensor {
	width: 360px;
	height: 350px;
	border: 1px solid rgb(0, 255, 0);
}

.sensor .leaflet-popup-content-wrapper {
	background-color: transparent;
	height: 100%;
	box-shadow: none;
}
.sensor .leaflet-popup-content {
	color: #fff;
}

.sensor .leaflet-popup-tip-container {
	display: none;
}

.sensor .value {
	width: 100px;
	height: 25px;
	font-size: 0.8rem;
	text-shadow: 0 0 5px #000, 0 0 5px #000, 0 0 5px #000;
	/* border: 1px solid green; */
}

/* Left */
.sensor .value.name {
	position: absolute;
	top: 20px;
	left: 20px;
	width: fit-content;
	text-align: left;
}

/* Left */
.sensor .value.site {
	position: absolute;
	top: 40px;
	left: 20px;
	width: fit-content;
	text-align: left;
}
.sensor .value.soil1 {
	position: absolute;
	top: 60px;
	left: 0px;
	width: fit-content;
	text-align: left;
}

.sensor .value.soil2 {
	position: absolute;
	top: 80px;
	left: 0px;
	text-align: left;
	width: fit-content;
}

.sensor .value.soil3 {
	position: absolute;
	top: 100px;
	left: 0px;
	text-align: left;
	width: fit-content;
}


.sensor .value.temperature {
	position: absolute;
	top: 40px;
	left: 200px;
	text-align: left;
	width: fit-content;
}

.sensor .value.humidity {
	position: absolute;
	top: 60px;
	left: 200px;
	text-align: left;
	width: fit-content;
}

.sensor .value.rain {
	position: absolute;
	top: 80px;
	left: 200px;
	text-align: left;
	width: fit-content;
}

.sensor .value.wind {
	position: absolute;
	top: 100px;
	left: 200px;
	text-align: left;
	width: fit-content;
}

/* Right */


.sensor .value.battery {
	position: absolute;
	top: 120px;
	left: 200px;
	text-align: left;
	width: fit-content;
}

.sensor .value.EC1 {
	position: absolute;
	top: 140px;
	left: 200px;
	text-align: left;
	width: fit-content;
}
.sensor .value.EC2 {
	position: absolute;
	top: 160px;
	left: 200px;
	text-align: left;
	width: fit-content;
}
.sensor .value.EC3 {
	position: absolute;
	top: 180px;
	left: 200px;
	text-align: left;
	width: fit-content;
}

.sensor .value.soilTemp1 {
	position: absolute;
	top: 120px;
	left: 20px;
	width: fit-content;
	text-align: left;
}

.sensor .value.soilTemp2 {
	position: absolute;
	top: 140px;
	left: 20px;
	width: fit-content;
	text-align: left;
}

.sensor .value.soilTemp3 {
	position: absolute;
	top: 160px;
	left: 20px;
	width: fit-content;
	text-align: left;
}

.sensor .value.Date {
	position: absolute;
	top: 180px;
	left: 20px;
	text-align: left;
	width: fit-content;
	/* left: 110px;
	text-align: center; */
}
.sensor .value.Card {
	position: absolute;
	top: 20px;
	right: 25px;
	text-align: right;
	width: 18vh;
	height: 26vh;
	background-size: cover;
	background-position: center center;
}

.sensor .value.Image {
	position: absolute;
	top: 120px;
	left: 100px;
	text-align: left;
	width: 25vh;
	height:50vh;
	display: inline-block;
	background-size: cover;
	background-position: center center;
	border-radius: 10%;
	-webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate-(90deg);
    transform: rotate(-90deg);
}
