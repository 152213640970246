.topNav {
    color: #fff;
    background-color: #415A43;
}

.scarpLogo {
    max-height: 50px;
}

.partnerWrapper {
    height: 50px;
    margin-left: 3px;
    margin-right: 3px;
    display: flex;
    align-items: center;
}

.partnerWrapper img  {
    max-height: 45px;
}

.link {
    color: #fff !important;
}


.link:hover{
    color: #fff !important;
    margin-top: -2px;
}

@media only screen and (max-width: 767px) {
    .link {
        color: #000 !important;
    }
    
    
    .link:hover{
        color: #000 !important;
        margin-top: -2px;
    }
  }