.lightTheme {
	border: 0px !important;
}

.darkTheme {
	background-color: #172b4d !important;
}

.darkTheme h3 {
	color: #fff;
}
